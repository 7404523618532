<template>
    <div class="max_cont_width flex_box" @mousedown="handleonmousedown($event)">
        <div class="left_menu">
            <button class="btn_Retr" @click="RetrievalResult" disabled>
                <img src="../../assets/img/search/btn_ic1.png" />
                Apply Filters
            </button>

            <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()" :disabled="true">
                <el-collapse-item title="Date" name="1" :disabled="true">
                    <template slot="title">
                        Date
                        <i class="header-icon el-icon-lock hedr-icon-ydong"></i>
                    </template>
                </el-collapse-item>
                <el-collapse-item title="Source" :disabled="true" name="2">
                    <template slot="title">
                        Source
                        <i class="header-icon el-icon-lock hedr-icon-ydong"></i>
                    </template>
                </el-collapse-item>

                <el-collapse-item title="Company" name="5" :disabled="true">
                    <template slot="title">
                        Company
                        <i class="header-icon el-icon-lock hedr-icon-ydong"></i>
                    </template>
                </el-collapse-item>
                <el-collapse-item title="Type" name="6" :disabled="true">
                    <template slot="title">
                        News Type
                        <i class="header-icon el-icon-lock hedr-icon-ydong"></i>
                    </template>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!-- 电脑端 -->
        <div class="right_box computer_right_cont_box">
            <div class="right_cont_box">
                <div class="table_box">
                    <el-table ref="treeTable" :key="refresh" :data="zhicaiTable1" border row-key="aaa_id" style="width: 100%" v-loading="loadig">
                        <el-table-column label="Name of Profile" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" @click="setiddetail(scope.row)" style="width: 345px" class="tbl_company line_1">
                                    {{ scope.row.attributes?.primary_name }}
                                </div>
                                <div v-show="!showTranslate" @click="setiddetail(scope.row)" style="width: 345px" class="tbl_company line_1">
                                    {{ scope.row.attributes?.primary_name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Country" width="113" prop="" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" style="width: 93px" class="line_1">
                                    {{ scope.row.attributes?.country_territory_name }}
                                </div>
                                <div v-show="!showTranslate" style="width: 93px" class="line_1">
                                    {{ scope.row.attributes?.country_territory_name }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="$route.query.type == 'Person'" label="Born date" width="180" prop="" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" style="width: 160px" class="line_1">
                                    {{ scope.row?.attributes?.date_of_birth == null ? '' : scope.row.attributes?.date_of_birth[0]?.year }}
                                </div>
                                <div v-show="!showTranslate" style="width: 160px" class="line_1">
                                    {{ scope.row?.attributes?.date_of_birth == null ? '' : scope.row.attributes?.date_of_birth[0]?.year }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="$route.query.type == 'Person'" label="Gender" prop="" align="left" width="120" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" class="line_1">
                                    {{ scope.row.attributes?.gender }}
                                </div>
                                <div v-show="!showTranslate" class="line_1">
                                    {{ scope.row.attributes?.gender }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Risk details" prop="" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" class="line_1">
                                    {{ scope.row.attributes?.title }}
                                </div>
                                <div v-show="!showTranslate" class="line_1">
                                    {{ scope.row.attributes?.title }}
                                </div>
                            </template>
                        </el-table-column>
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">Nothing detrimental noted on your search of "{{ searchinput }}"</div>
                        </template>
                    </el-table>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                    </div>
                </div>
            </div>
        </div>
        <Phonelist  :total="page.total"  :page="page" :zhicaiTable1="zhicaiTable1" :searchName="searchName"> :</Phonelist>
    </div>
</template>
<script>
import bus from '@/components/bus';
import { searchriskAndComp } from '@/api/searchApi.js';
import { getCompanyDetailMenuList, ordersAdd } from '../../api/companyApi';
import Page from '@/components/page';
import Phonelist  from './pohnelist.vue';
import { mixins1 } from '@/mixins/index';


export default {
    mixins: [mixins1],
    components: { Page,Phonelist },
    data() {
        return {
            loading: true,

            value: 'Entity',
            zhicai: 'Sanction',
            zhicaiTable: [],
            zhicaiTable1: [],
            searchinput: '',
            showTranslate: false,
            searchoptions: [
                {
                    value: 'Entity',
                    label: 'Company',
                },
                {
                    value: 'Person',
                    label: 'Person',
                },
            ],
            isShowTooltip: false,
            searchCode: '',
            loadig: true,
            refresh: 0,

            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },

            pageNo: 1,
            pageSize: 10,
            searchName: '',
            openMenu: [],
            // 下载
            title: 'Company',
            json_fields: {
                'Company Name': 'company_name_en',
                Province: 'province_en',
                City: 'city_en',
                Industry: 'isic_en',
                'Inc.Yr.': 'incorporation_year',
                'Company Status': 'status_en',
                'Company Type': 'company_type_en',
                'Company Size': 'scale_en',
            },

            //权限接口获取
            powerResult: {},
            token: localStorage.getItem('token'),
        };
    },
    created() {
        if (!this.token) this.getPower();
            window.addEventListener('resize', this.handleResize)
          this.handleResize()
        // this.getConditions();
    },
    mounted() {},
    beforeDestroy() {
        this.$off('closeReportDialog');
          window.removeEventListener('resize', this.handleResize)
    },
    updated() {
        window.addEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    destroyed() {
        bus.$off('headLogFlg');
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getPower();
                }
            },
            immediate: true,
        },
    },
    methods: {
              handleResize() {
       if (window.innerWidth < 728) {
         // 手机端逻辑
          this.page.pageSize=50
            } else {
         // 非手机端逻辑
           this.page.pageSize=10
         
           }
        },
        checktype() {},
        zhicaiseach() {
            const searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            // const type = this.countryCheck ? this.countryCheck : this.$route.query.companyCountrydqs;
            const type = this.$route.query.type ? this.$route.query.type : 'Entity';
            searchriskAndComp({
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
                keyword: searchName,
                type: type,
            }).then(res => {
                if (res && res.code) {
                    this.page.total = res.data.total;
                    this.zhicaiTable1 = res.data.rows;
                    this.loadig = false;
                } else {
                    this.zhicaiTable1 = [];
                    this.page.total = this.zhicaiTable1.length;
                    this.loadig = false;
                }
            });
        },
        async setiddetail(row) {
            let powerall = this.powerResult.list[4].power;
            if (powerall) {
                let params = 'code=' + row.id + '&type=' + 11;
                let res = await getCompanyDetailMenuList(params);
                if (res && res.code) {
                    let power = res.data[0].power;
                    if (power) {
                        let routeData = this.$router.resolve({
                            path: '/sanctions/companysnapshot?type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name + '&country=' + row.attributes.country_territory_name + '&information=Search Sanctions',
                            // path: '/search/newsanction?' + '&type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name,
                        });

                        window.open(routeData.href, '_blank');
                    } else {
                        this.getOrdersAdd(row).then(res => {
                            if (res) {
                                let routeData = this.$router.resolve({
                                    path: '/sanctions/companysnapshot?type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name + '&country=' + row.attributes.country_territory_name + '&information=Search Sanctions',
                                    // path: '/search/newsanction?' + '&type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name,
                                });

                                window.open(routeData.href, '_blank');
                            }
                        });
                    }
                }
            } else {
                this.message();
            }
        },
        zhankai() {
            let hasPower = this.powerResult.list[0].power;
            if (!hasPower) {
                this.openMenu = [];
                this.message();
            }
        },

        handleonmousedown(e) {
            // 处理登录功能：
            // 未登录  允许点击【翻译】和【详情】
            const token = localStorage.getItem('token');

            if (!token || token == null) {
                if (!(e.target?._prevClass === 'no_login')) {
                    // bus.$emit('headLogFlg', true); //是否显示登录
                    bus.$emit('handleShowLogin', true); //是否显示登录
                }
            }
        },

        //权限
        async getPower() {
            if (this.token) {
                this.loadig = true;
            } else {
                this.loadig = false;
            }
            let params = 'type=10';
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                this.powerResult = res.data[0];
                // this.token = sessionStorage.getItem('token');
                this.zhicaiseach();
                // this.getCollData();
            }
        },

        //二次查询
        RetrievalResult() {
            let power = this.powerResult.list[0].power;
            if (power) {
                // this.searchCompanyByName();
                this.getConditions();
            } else {
                this.message();
            }
        },

        //分页
        onPageChange(pageNo) {
            let power = this.powerResult.list[3].power;
            if (power) {
                this.page.pageNo = pageNo;
                this.zhicaiseach();
            } else {
                this.message();
            }
        },
        currtentPageChange(pageSize) {
            let power = this.powerResult.list[3].power;
            if (power) {
                this.page.pageSize = pageSize;
                this.zhicaiseach();
            } else {
                this.message();
            }
        },
        getOrdersAdd(row) {
            const type = 20;
            //1  :线上报告
            let params = {
                companyName: row.attributes?.primary_name, //"贸易产品描述",
                aaaId: row.id, //"贸易产品描述code",
                companyCountry: row.attributes?.country_territory_name, // 国家二位编码
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            return new Promise((resolve, reject) => {
                ordersAdd(params).then(result => {
                    let data = result;
                    if (data && data.code) {
                        setTimeout(() => {
                            resolve(true);
                        }, 1500);

                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that = this;
                        let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;
                        let routeData = '';
                        setTimeout(function () {
                            if (userinfo.type == '3') {
                                routeData = that.$router.resolve({
                                    path: '/account/quota',
                                });
                            } else {
                                routeData = that.$router.resolve({
                                    path: '/account/addCredit',
                                });
                            }

                            window.open(routeData.href, '_blank');
                        }, 1000);

                        resolve(false);
                    }
                });
            });
        },
        // prmise对象
        // textone(){
        //     return new Promise((resolve,reject)=>{

        //         resolve({id:1})
        //         reject(false)
        //     })
        // }
        // textone.then(res)
        
    },
};
</script>
<style scoped>
.table_box /deep/ .el-table th > .cell {
    padding-right: 0;
    padding-left: 0;
}
.add_list {
    margin-bottom: 20px;
}
.add_list /deep/ .el-radio-button__inner {
    display: flex;
    height: 62px;
    padding: 10px 9px 8px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
}
.add_list /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #1290c9;
    color: #ffffff;
    box-shadow: none;
}

.add_list /deep/ .el-radio-button__inner:hover {
    background: #e7eaed;
}

.is-active .add_des_txt,
.is-active .add_des_title {
    color: #ffffff;
}

.add_img {
    height: 42px;
    flex: none;
}

.add_img img {
    width: 38px;
    height: 42px;
    margin-right: 9px;
}

.add_des_title {
    width: 370px;
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 20px;
    text-align: left;
}

.add_des_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
    text-align: left;
}

.btn_return,
.btn_create {
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: #e7eaed;
    border-radius: 12px;
    font-size: 16px;
    color: #022955;
}

.btn_create {
    width: 180px;
}

.btn_return {
    width: 140px;
}

.create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}

.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}

.btn_comp_box {
    text-align: right;
}

.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}
/* .table_box .el-table th>.cell :first-child{
    padding-left: 30px;
} */

.tree_table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 40px !important;
}
.check_item::after {
    content: '';
    position: absolute;
    left: 48%;
    top: 20px;
    bottom: 20px;
    width: 2px;
    background-color: #e7eaed;
}

.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}

.company_box /deep/ .el-checkbox__input {
    float: left;
}
.company_box /deep/ .el-checkbox__label {
    color: #022955;
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    float: left;
    padding-top: 2px;
}
.yangsi {
    padding-left: 30px !important;
}
.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}

.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}

.comp_search .coll_search_btn {
    top: 7px;
}
.condition_lab {
    display: flex;
    flex-wrap: wrap;
}
.condition_labb {
    float: left;
    max-width: 888px;
}
.condition_labb /deep/ .el-tag {
    background-color: #ffffff;
    border-color: #e7eaed;
    height: 40px;
    line-height: 20px;
    padding: 10px;
    color: #022955;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 14px;
}
.condition_labb /deep/ .el-tag .el-tag__close {
    color: #f56b6b;
    font-weight: 600;
}
.condition_labb /deep/ .el-tag .el-tag__close:hover {
    color: #f56b6b;
    background-color: #fff;
}
.indystry .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100% !important;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
/* .table_box /deep/ .el-table__empty-block{
    justify-content: flex-start !important;
    display: block;
    text-align: center;
} */
.el-table td,
.el-table /deep/ th.is-leaf {
    border-bottom: 1px solid #e7eaed;
    border-right: none;
    padding-left: 10px;
}
.table_box /deep/ .el-table .cell {
    padding-right: 0px;
}
.title-left {
    display: flex;
    font-size: 16px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #022955;
    margin-right: 20px;
}
.btn_Retr2 {
    margin-top: 20px;
    width: 260px;
    height: 40px;
    line-height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn_Retr2_title {
    width: 50%;
    background: #1290c9;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    border-radius: 12px;
    cursor: pointer;
}
.newslsit {
    margin: 15px 0px;
}
.newslsit /deep/ .el-checkbox__input {
    display: none;
}
.impact_status {
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 50%;
}
.impact_status img {
    width: 14px;
    height: 14px;
    margin-top: 6px;
}
.impact_status.green {
    background: #55b419;
}
.impact_status.hui {
    background: #ccc;
}
.impact_status.blue {
    background: #1290c9;
}
.impact_status.red {
    background: #ff7600;
}
.cas_loc_box /deep/ .el-cascader-node__label,
.cas_loc_box .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 140px;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
.cas_loc_box1 .casca_lab {
    width: 114px;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
.table_box_two .no_date_box {
    width: 100%;
    /* margin: 5vh auto 20vh; */
    padding: 20px;
}
.table_box_two .no_img {
    width: 250px;
    height: 197px;
    margin: 0 auto;
    margin-bottom: 4px;
}
.table_box_two .no_img img {
    width: 100%;
    height: auto;
}
.table_box_two .des_one {
    text-align: center;
    line-height: 32px;
    font-size: 26px;
    font-family: 'Arial Bold';
    color: #1290c9;
    margin-bottom: 10px;
}
.table_box_two .des_two {
    font-size: 12px;
    color: #022955;
    line-height: 24px;
}
.seach-title {
    font-size: 16px;
    text-align: center;
    margin-right: 80px;
    color: #022955 !important;
}
.seach-condition {
    width: 60%;
    /* margin: auto;
    margin-top: 20px; */
    position: relative;
}
.seach-condition /deep/ .el-select .el-input {
    width: 130px;
}
.seach-condition .shuxian {
    position: absolute;
    left: 130px;
    top: 13px;
    height: 13px;
    width: 1px;
    background-color: #ccc;
    z-index: 1;
}
.seach-condition /deep/ .el-input-group__prepend {
    background-color: #fff;
    border-radius: 14px 0 0 14px;
}
.seach-condition /deep/ .el-select .el-input .el-input__inner {
    /* background-color: #fff; */
    /* border-radius: 0; */
    border: none;
}
.seach-condition /deep/.el-select .el-icon-arrow-up:before {
    /* border-left: 1px solid #ccc; */
    /* padding-left: 5px; */
    color: #ccc !important;
}
.seach-condition /deep/ .el-input__inner {
    border-radius: 0 14px 14px 0;
    background-color: #fff;
    border-left: none;
    color: #022955 !important;
}

.seach-condition /deep/ .el-input__inner:focus {
    border-color: #ccc;
}
.seach-condition .seach-text {
    line-height: 30px;
    font-size: 14px;
}
.seaction-table {
    margin-top: 15px;
}
.name-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.head-title {
    color: #022955;
    font-size: 16px;
}
.name-information {
    width: 800px;
    display: flex;
    justify-content: space-around;
}
.information-box {
    font-size: 14px;
}
.information-box-text {
    font-size: 14px;
    color: #022955;
}
.information-box-text1 {
    font-size: 14px;
    color: #1290c9;
    line-height: 25px;
}
.name-overview {
    display: flex;
    justify-content: space-between;
}
.name-overview-box {
    width: 50%;
    padding-right: 20px;
    /* font-size: 15px; */
}
.name-overview-box-title {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.seach-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.name-overview-box-text {
    padding: 20px;
}
.linheight {
    line-height: 20px;
}
.hedr-icon-ydong {
    position: absolute;
    left: 215px;
    font-weight: 600;
}
/* .left_menu /deep/ .el-collapse-item__arrow{
display: none;
} */
.left_menu /deep/ .el-collapse-item__header {
    position: relative;
}
@media (max-width: 821px) {
    .phone_right_cont_box{
       display: block !important;
    }
    .computer_right_cont_box{
        display: none;
    }
 
}
</style>
