<template>
        <div  class="right_box phone_page_bg" style="margin-top:40px !important">
                 <div class="phone_right_cont_box line_2 phone_page_bg" style="margin-bottom:10px !important">{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
           <div class="right_cont_box">
                    <div class="table_box">
                          <ul class="phone_table_box">
                        <li class="phone_table_box_item" v-for="(item,index) in zhicaiTable1" :key="index">
                    
                         <div class="phone_table_box_item_right ">
                   
                           <h1 class="line_1" style="width: 300px" @click="setiddetail(item)"  > {{item.attributes?.primary_name}}</h1>
                 
               
               
                           <h2 class="line_1" > Country: {{item.attributes?.country_territory_name}}</h2>
                           <h2 class="line_1"><span >Risk details:{{item.attributes?.title}}</span></h2>
                           <h2  v-if="$route.query.type == 'Person'"> <div  style="width: 160px" class="line_1">Born date:
                                    {{ item?.attributes?.date_of_birth == null ? '' : item.attributes?.date_of_birth[0]?.year }}
                                </div></h2>
                            <h2  v-if="$route.query.type == 'Person'"  >
                                 <div  class="line_1">
                                    {{ item?.attributes?.gender }}
                                </div>
                          
                           </h2>
                           <!-- <h2 class="line_1"><span >Transaction:({{item.transcations}})</span> <span style="float:right"> {{item.incDate}}</span></h2> -->
                         </div>
                        </li>
                    </ul>
                   
                    
                    </div>
                </div>
        </div>
</template>
<script>




export default {

props:{
    zhicaiTable1:{
    type:Array,
       default:function(){}
  },
    page:{
    type:Object,
       default:function(){}
  },
     searchName:{
    type:String,
       default:''
  },
},
    data() {
        return {
            loading: true,

        };
    },
    methods:{
             setiddetail(row) {
            let routeData = this.$router.resolve({
                path: '/search/newsanction?' + '&type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name,
            });

            window.open(routeData.href, '_blank');
        },
    }

};
</script>
<style scoped>

@media (max-width: 821px) {
    .phone_right_cont_box{
       display: block !important;
    }
    .computer_right_cont_box{
        display: none;
    }
    .phone_right_cont_box{
    width: 100%;
    /* padding: 15px; */
    padding:0px 20px;
    /* box-shadow: 0px 2px 6px 1px rgba(10,33,57,0.14); */
    background: #FFFFFF;
    }
    .phone_table_box{

    }
    .phone_table_box_item{
     display: flex;
     /* justify-content: center; */
     /* align-items: center; */
     padding: 20px 0;
     border-bottom:1px solid #e0dce5;
    }
   .table_box .phone_table_box_item img {
 
     width: 28px;
     height: 17px;
     flex: none;
     margin-right: 4px;
     box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right{
      font-size: 14px;
      margin-left: 20px;
         width: 85%;
         
    }
    .phone_table_box_item_right h1{
        font-size: 14px;
         font-weight: normal;
             
   
    }
     .phone_table_box_item_right h2{
        font-size: 12px;
        color: #656373;
         font-weight: normal;
         line-height: 22px;
       
       
    }
    .line_1{
            white-space: nowrap !important;
    }
}
</style>
